

import "./techno.css"
import logos from "./logos-tecnologies.jpg"

function Techno() {

  return (
    <div className="techno" >
    
    
    <img src={logos} alt="" />
    
    </div> 
  )
}

export default Techno