import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import ruido from "./img/ruido.gif"
import signal from "./img/signa-1.jpg"
import slide_1 from "./img/sl-1.jpg"
import slide_2 from "./img/sl-2.jpg"
import slide_3 from "./img/sl-3.jpg"
import slide_4 from "./img/sl-4.jpg"
import slide_5 from "./img/sl-5.jpg"
import slide_7 from "./img/sl-7.jpg"
import laptop from "./img/laptop_.png"
import cable from "./img/cable-usb.png"
import vhs from "./img/vhs.gif"
import "../slider/slider.css";

export default function Slider() {
  const el = useRef();
  const tl = useRef();

  useLayoutEffect(()=>{    
    let mm = gsap.matchMedia(el);
    
    mm.add("(min-width: 656px)", () => {
      tl.current = gsap.timeline({ repeat: -1 })
      .set("#cable", { y: 355, x: -109, opacity: 100 })
      .set("#laptop", { y: -37, x: -85, opacity: 100 })
        .set("#ruido", { opacity: 100 })
        .from("#signal", { opacity: 0, duration: .5, })
        .set("#signal", { opacity: 100 })
        .to("#signal", { opacity: 0 })

        .from("#slide_1", { opacity: 0, duration: .5, delay:2.3  })
        .set("#slide_1", { opacity: 100 })
        .to("#slide_1", { opacity: 0, duration: .5,  })
        .set("#slide_1", { opacity: 0 , delay:.3})

        .from("#slide_2", { opacity: 0, duration: .5,  })
        .set("#slide_2", { opacity: 100 })
        .to("#slide_2", { opacity: 0, duration: .5,  })
        .set("#slide_2", { opacity: 0 , delay:.3})

        .from("#slide_3", { opacity: 0, duration: .5,  })
        .set("#slide_3", { opacity: 100 })
        .to("#slide_3", { opacity: 0, duration: .5,  })
        .set("#slide_3", { opacity: 0 , delay:.3})
        
        .from("#slide_4", { opacity: 0, duration: .5,  })
        .set("#slide_4", { opacity: 100 })
        .to("#slide_4", { opacity: 0, duration: .5,  })
        .set("#slide_4", { opacity: 0 , delay:.3})
        
        .from("#slide_5", { opacity: 0, duration: .5,  })
        .set("#slide_5", { opacity: 100 })
        .to("#slide_5", { opacity: 0, duration: .5,  })
        .set("#slide_5", { opacity: 0 , delay:.3})
       
        .from("#slide_7", { opacity: 0, duration: .5,  })
        .set("#slide_7", { opacity: 100 })
        .to("#slide_7", { opacity: 0, duration: .5,  })
        .set("#slide_7", { opacity: 0 , delay:.3})
    })

    mm.add("(max-width: 655px)", () => {
      tl.current = gsap.timeline({ repeat: -1 })
      .set("#cable", { y: 225, x: -109, opacity: 0 })
        .set("#laptop", { y: -20, x: -95, opacity: 100 })
        .set("#ruido", { opacity: 100 })
        .from("#signal", { opacity: 0, duration: .5, })
        .set("#signal", { opacity: 100 })
        .to("#signal", { opacity: 0 })

        .from("#vhs", { opacity: 0, duration: .5, })
        .set("#vhs", { opacity: 100, delay: 3.9 })
        .to("#vhs", { opacity: 0 })

        .from("#slide_1", { opacity: 0, duration: .5, delay:2.3  })
        .set("#slide_1", { opacity: 100 })
        .to("#slide_1", { opacity: 0, duration: .5,  })
        .set("#slide_1", { opacity: 0 , delay:.3})

        .from("#slide_2", { opacity: 0, duration: .5,  })
        .set("#slide_2", { opacity: 100 })
        .to("#slide_2", { opacity: 0, duration: .5,  })
        .set("#slide_2", { opacity: 0 , delay:.3})

        .from("#slide_3", { opacity: 0, duration: .5,  })
        .set("#slide_3", { opacity: 100 })
        .to("#slide_3", { opacity: 0, duration: .5,  })
        .set("#slide_3", { opacity: 0 , delay:.3})
        
        .from("#slide_4", { opacity: 0, duration: .5,  })
        .set("#slide_4", { opacity: 100 })
        .to("#slide_4", { opacity: 0, duration: .5,  })
        .set("#slide_4", { opacity: 0 , delay:.3})
        
        .from("#slide_5", { opacity: 0, duration: .5,  })
        .set("#slide_5", { opacity: 100 })
        .to("#slide_5", { opacity: 0, duration: .5,  })
        .set("#slide_5", { opacity: 0 , delay:.3})
        
        .from("#slide_7", { opacity: 0, duration: .5,  })
        .set("#slide_7", { opacity: 100 })
        .to("#slide_7", { opacity: 0, duration: .5,  })
        .set("#slide_7", { opacity: 0 , delay:.3})
    })




    ;return () => mm.revert(); }, [])

  return (
<div className="bkRuido" ref={el}>

    <img id="cable"   className="cable"   src={cable} alt="cable-usb" />
    <img id="laptop"   className="laptop"   src={laptop} alt="Imagenddwq 2" />
    <img id="ruido"   className="image"   src={ruido} alt="Imagen 2" />
    <img id="signal"   className="image"  src={signal} alt="Imagen 2" />
    <img id="vhs"   className="image"  src={vhs} alt="Imagedn 2" />
    <img id="slide_1"  className="image"  src={slide_1} alt="Imagen 675" />
    <img id="slide_2"  className="image"  src={slide_2} alt="Imagen 4" />
    <img id="slide_3"  className="image"  src={slide_3} alt="Imagen 67dw5" />
    <img id="slide_4"  className="image"  src={slide_4} alt="Imagen 4wd" />
    <img id="slide_5"  className="image"  src={slide_5} alt="Imagen 4wd" />
    <img id="slide_7"  className="image"  src={slide_7} alt="Imagen 4wd" />
    </div>)
}
