import { createContext, useReducer, useEffect, useState } from "react";










export const ThemeContext = createContext();

const INITIAL_STATE = { darkMode: false };

const themeReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      return { darkMode: !state.darkMode };
    default:
      return state;
  }
};

export const ThemeProvider = (props) => {
  const [state, dispatch] = useReducer(themeReducer, INITIAL_STATE);

  const [post, setPost] = useState([]);
  const [filterPost, setFilterPost] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch(`https://cms-server.rene-huber.eu/api/posts?limit=0`);
    const data = await response.json();
     setPost(data.docs);
     setFilterPost(data.docs);
     setLoading(false);
     
    
  }

  useEffect(() => {
    fetchData();
  }, []);


  
  const value ={
    post,
    filterPost,
    setFilterPost,
    state, dispatch, loading
  }

  

  return (
    <ThemeContext.Provider value={value }>
      
      {props.children}
    </ThemeContext.Provider>
  );
};
