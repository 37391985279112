// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/puntero.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.follow{
    transform: rotate(-90deg); 
    position: fixed;
    right: -55px;
    top: 45%;
    color: #000;
   text-decoration: none;
    font-family: 'Rubik', sans-serif;
    z-index: 999;
    cursor:url(${___CSS_LOADER_URL_REPLACEMENT_0___}), auto
   
}

.follow a{ color: #000; text-decoration: none; height: 99px; cursor: pointer;}
#dark .follow a{ color: rgb(204, 204, 204); text-decoration: none; height: 99px; cursor: pointer}

#dark .follow{
    color:rgb(204, 204, 204);
}
#light .follow{
    color:rgb(0, 0, 0);
}


@media screen and (max-width: 655px) {
    .follow{ display: none; }
}`, "",{"version":3,"sources":["webpack://./src/components/follow/follow.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,WAAW;GACZ,qBAAqB;IACpB,gCAAgC;IAChC,YAAY;IACZ;;AAEJ;;AAEA,WAAW,WAAW,EAAE,qBAAqB,EAAE,YAAY,EAAE,eAAe,CAAC;AAC7E,iBAAiB,yBAAyB,EAAE,qBAAqB,EAAE,YAAY,EAAE,eAAe;;AAEhG;IACI,wBAAwB;AAC5B;AACA;IACI,kBAAkB;AACtB;;;AAGA;IACI,SAAS,aAAa,EAAE;AAC5B","sourcesContent":[".follow{\n    transform: rotate(-90deg); \n    position: fixed;\n    right: -55px;\n    top: 45%;\n    color: #000;\n   text-decoration: none;\n    font-family: 'Rubik', sans-serif;\n    z-index: 999;\n    cursor:url(\"../../img/puntero.gif\"), auto\n   \n}\n\n.follow a{ color: #000; text-decoration: none; height: 99px; cursor: pointer;}\n#dark .follow a{ color: rgb(204, 204, 204); text-decoration: none; height: 99px; cursor: pointer}\n\n#dark .follow{\n    color:rgb(204, 204, 204);\n}\n#light .follow{\n    color:rgb(0, 0, 0);\n}\n\n\n@media screen and (max-width: 655px) {\n    .follow{ display: none; }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
