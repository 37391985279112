// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.letras{
    color: rgb(255, 0, 149);
    font-family: 'Rubik', sans-serif;
    font-size: 47px;
    line-height: 33px;
    text-shadow: 1px 1px #ffffff;
    padding-top: 25px;
}


@media screen and (max-width: 1223px) {

    .letras{font-size: 31px;}

}

@media screen and (max-width: 955px) {
    .letras{font-size: 15px;
        padding-top: 5px;}
}

@media screen and (max-width: 655px) {
    .letras{font-size: 31px;}
}


@media screen and (max-width: 397px) {
    
}`, "",{"version":3,"sources":["webpack://./src/components/sliderText/sliderText.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gCAAgC;IAChC,eAAe;IACf,iBAAiB;IACjB,4BAA4B;IAC5B,iBAAiB;AACrB;;;AAGA;;IAEI,QAAQ,eAAe,CAAC;;AAE5B;;AAEA;IACI,QAAQ,eAAe;QACnB,gBAAgB,CAAC;AACzB;;AAEA;IACI,QAAQ,eAAe,CAAC;AAC5B;;;AAGA;;AAEA","sourcesContent":[".letras{\n    color: rgb(255, 0, 149);\n    font-family: 'Rubik', sans-serif;\n    font-size: 47px;\n    line-height: 33px;\n    text-shadow: 1px 1px #ffffff;\n    padding-top: 25px;\n}\n\n\n@media screen and (max-width: 1223px) {\n\n    .letras{font-size: 31px;}\n\n}\n\n@media screen and (max-width: 955px) {\n    .letras{font-size: 15px;\n        padding-top: 5px;}\n}\n\n@media screen and (max-width: 655px) {\n    .letras{font-size: 31px;}\n}\n\n\n@media screen and (max-width: 397px) {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
