export const categories = [
  {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Wordpress',
      value: 'Wordpress',
    },
    {
      label: 'React',
      value: 'React',
    },
    {
      label: 'Graphic Design',
      value: 'Graphic Design',
    }


  // {
  //     'name': 'All',
  //     'id': '0',
  //   },
  //   {
  //     'name': 'Wordpress',
  //     'id': '28',
  //   },
  //   {
  //     'name': 'React',
  //     'id': '12',
  //   },
  //   {
  //     'name': 'Vanilla Js',
  //     'id': '16',
  //   },
  //   {
  //     'name': 'HTML CSS',
  //     'id': '35',
  //   },

]