// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide2{ 
    width: 593px;}

.imagen{
    position:absolute;
    height: 603px;
    width: 593px;
}

@media screen and (max-width: 1367px) {
    .imagen{
      
        height: auto;
        width: 393px;
    }
    .slide2{ 
        width: 427px;}
}`, "",{"version":3,"sources":["webpack://./src/components/slider_2/slide_2.css"],"names":[],"mappings":"AAAA;IACI,YAAY,CAAC;;AAEjB;IACI,iBAAiB;IACjB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI;;QAEI,YAAY;QACZ,YAAY;IAChB;IACA;QACI,YAAY,CAAC;AACrB","sourcesContent":[".slide2{ \n    width: 593px;}\n\n.imagen{\n    position:absolute;\n    height: 603px;\n    width: 593px;\n}\n\n@media screen and (max-width: 1367px) {\n    .imagen{\n      \n        height: auto;\n        width: 393px;\n    }\n    .slide2{ \n        width: 427px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
