import { useContext } from "react"
import { ThemeContext } from '../../context'
import { motion } from "framer-motion";
import {Link, useLocation} from "react-router-dom"
import load from '../../img/loading.gif'

const Posts = () => {
 const {filterPost, loading } = useContext(ThemeContext);
 

 let location = useLocation();
  switch (location.pathname) {
    case '/':
  
    if (loading) {
      return <img src={load} alt={""} />
    }
  
    

  return (filterPost.slice(0, 8).map((el) => (
    <motion.div
      className="image-card"
      key={el.id}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Link to={`/onepost/${el.id}`}>
        <img src={el.Image.url} alt={el.Title} />{" "}
      </Link>
      
    </motion.div>
  ))); default: 

  if (loading) {
    return <img src={load} alt={""} />
  }
  return (filterPost.map((el) => (
    <motion.div
      className="image-card"
      key={el.id}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Link to={`/onepost/${el.id}`}>
        <img src={el.Image.url} alt={el.Title} />
      <h2 className="titulo_posts"> {el.Title}</h2>
      </Link>
     
    </motion.div>
  )))
  
}
}
export default Posts