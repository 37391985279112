import { Routes, Route} from "react-router-dom"
import { useContext } from "react";
import { ThemeContext } from "./context";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Footer from "./components/footer/Footer";
import Testimonials from "./pages/Testimonials";
import Follow from "./components/follow/Follow";

import OnePost from "./components/OnePost/OnePost"

import Enviado from "./pages/Enviado";




const App = () => {
  
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div id={ darkMode ? "dark" : "light"}>
   
      <Follow/>
      <Navbar />
      
<Routes>

<Route path = "/" element={<Home/>} />

<Route path= "/portfolio" element={<Portfolio/>} />
<Route path= "/contact" element={<Contact/>} />
<Route path= "/testimonials" element={<Testimonials/>} />
<Route path="/onepost/:id" element={<OnePost/>}/>
<Route path="/enviado" element={<Enviado/>}/>

</Routes>

<Footer />
    </div>
  );
};

export default App;
