import { useEffect } from 'react';
import ButtonFilters from "../components/ButtonFilter/ButtonFilters"
import Posts from "../components/Posts/Posts"

import { AnimatePresence } from "framer-motion";

export default function Portfolio() {
  useEffect(() => {
    // Establece el desplazamiento de la ventana al inicio de la página
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="gridbox">

    <ButtonFilters />
    <div className="image-container">
    <AnimatePresence>
          <Posts />
          </AnimatePresence>  
          </div>
    </div>
  )
}
