import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./about.css";
import chica from "../../img/chica.png";
import bag from "../../img/bolsas.png";

gsap.registerPlugin(ScrollTrigger)

const About = () => {
const bagRef = useRef(null)
useLayoutEffect(()=>{
const bolsa = bagRef.current;

gsap.fromTo(bolsa,{x:-90},{duration: 1, x:0, 
  scrollTrigger:{
  trigger: bolsa,
  scrub: 1,
  // markers: true,
  start: "top 70%",
 
  
 
 

 
}})

}, [])

  return (
    <div className="wrap_about">



<div className="wrap_chica">
<img src={bag} alt="" ref={bagRef} className="bolsa"/>
<img src={chica} alt="" className="chica"/>
</div>

<div className="text_chica">
  <h1>WHY YOU SHOULD<br/> HAVE A WEBSITE<span className="color1"> ?</span></h1>
  <div className="textoChicaWrap">
  <p>Well over half of poblation have access to almost any imaginable shopping option in their pockets readily available and easy to use
without ever having to get in the car or ask mom for a ride to the
store.  Anyone can have access to your business, blog or project  24hours 
all year long. and at the same time, have a broader horizon, 
where there are other types of clients that today are more 
inclined  to the digital / online field.</p>
</div>
</div>


    </div>
  );
};

export default About;
