import { createContext, useReducer, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Cursor from "./components/cursor/Cursor";


export const MouseContext = createContext();

export const MouseProvider = ({ children }) => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const [cursorVariant, setCursorVariant] = useState("default");

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
    },
    text: {
      borderRadius: 50,
      height: 117,
      width: 117,
      x: mousePosition.x - 67,
      y: mousePosition.y - 67,
      backgroundColor: "yellow",
      mixBlendMode: "difference",
      transition: {
        type: "spring",
        mass: 0.9
      }
    },

    text_h: {
      borderRadius: 50,
      height: 51,
      width: 51,
      x: mousePosition.x - 25,
      y: mousePosition.y - 25,
      backgroundColor: "yellow",
      mixBlendMode: "difference",
     
    }
   
  }

  const textEnter = () => setCursorVariant("text");
  const textLeave = () => setCursorVariant("default");
  
  const hEnter = () => setCursorVariant("text_h");
  const hLeave = () => setCursorVariant("default");


  return (
  <MouseContext.Provider value={{textEnter, textLeave,hEnter,hLeave,
    setCursorVariant,mousePosition, cursorVariant, variants }}>
    {children}
    </MouseContext.Provider>
  )
};
