// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t{
    width: 50px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    position: relative;
    top: 3px;
    right: 0px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.t-icon{
    width: 15px;
    height: 15px;
}

.t-button{
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: rgb(253, 255, 222);
    position: absolute;
    cursor: pointer;
}

#dark .t {
    border: 1px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}
#dark .t-button{
    background-color: rgb(0, 0, 0);
}

@media screen and (max-width: 960px) {
    .t{ margin: 0 auto;
    }

}

`, "",{"version":3,"sources":["webpack://./src/components/toggle/toggle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,8BAA8B;IAC9B,8BAA8B;IAC9B,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;IACpC,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,8BAA8B;IAC9B,oCAAoC;AACxC;AACA;IACI,8BAA8B;AAClC;;AAEA;IACI,IAAI,cAAc;IAClB;;AAEJ","sourcesContent":[".t{\n    width: 50px;\n    height: 25px;\n    border-radius: 20px;\n    border: 1px solid rgb(0, 0, 0);\n    background-color: rgb(0, 0, 0);\n    position: relative;\n    top: 3px;\n    right: 0px;\n    z-index: 999;\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n}\n\n.t-icon{\n    width: 15px;\n    height: 15px;\n}\n\n.t-button{\n    width: 21px;\n    height: 21px;\n    border-radius: 50%;\n    background-color: rgb(253, 255, 222);\n    position: absolute;\n    cursor: pointer;\n}\n\n#dark .t {\n    border: 1px solid rgb(0, 0, 0);\n    background-color: rgb(255, 255, 255);\n}\n#dark .t-button{\n    background-color: rgb(0, 0, 0);\n}\n\n@media screen and (max-width: 960px) {\n    .t{ margin: 0 auto;\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
