import { useContext } from "react"
import { motion } from "framer-motion";
import { MouseContext} from "../../MouseCTX"

import "./intro.css";
import Slider from "../slider/Slider";
import SliderText from "../sliderText/SliderTex";
import Cantv from "../btnCANTV/Cantv"


const Intro = () => {
  const { cursorVariant, textLeave,variants, textEnter, hEnter,hLeave,} = useContext(MouseContext)

  return (
    <div className="caja"  >
     <motion.div className='cursor'
        variants={variants}
        animate={cursorVariant}
      />
      <div className="texto" >
    
        <h1 onMouseEnter={textEnter} onMouseLeave={textLeave}>
          IF YOU ARE
          <br />
          NOT ONLINE:
        </h1>

        <SliderText />
        <Cantv />
      </div>

      <div className="slider" onMouseEnter={hEnter} onMouseLeave={hLeave}>
        <Slider />
      </div>
    </div>
  );
};

export default Intro;
