import Typed from "react-typed";
import "./sliderText.css"

export default function SliderTex() {
  return (
    <div className="letras">
        <Typed
          strings={[
            "Lose customers",
            "Available 24/7?",
            "You don`t exist",
          ]}
          typeSpeed={23}
          backSpeed={30}
          cursorChar= {"_"}
          loop
          
        />
      </div>
  )
}
