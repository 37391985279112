// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.techno{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    margin: 0 auto 20px auto;

}
.techno img{
    width: 100%;
  height: auto;
 
}

`, "",{"version":3,"sources":["webpack://./src/components/techno/techno.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,wBAAwB;;AAE5B;AACA;IACI,WAAW;EACb,YAAY;;AAEd","sourcesContent":[".techno{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 80vw;\n    margin: 0 auto 20px auto;\n\n}\n.techno img{\n    width: 100%;\n  height: auto;\n \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
