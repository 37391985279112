import { useState, useEffect, useContext } from "react"
import { Link, useParams } from "react-router-dom";
import { ThemeContext } from '../../context'

import load from '../../img/loading.gif'


 
export default function OnePost() {
  const { loading } = useContext(ThemeContext);
  const {id} = useParams();
  
  const [text, setText] = useState({
        title: "",
        desc: "",
        categorie: "",})

  const [post, setPost] = useState([]);
   const [link, setLink] = useState(null);


    const fetchData = async () => {
      const response = await fetch(`https://cms-server.rene-huber.eu/api/posts/${id}`);
      const data = await response.json();
    setText({
          title: data.Title,
          desc: data.Description[0].children[0].text,
          categorie: data.Categories.join(" / ")
        })
        if (data.Description[0].children[1]) {
   
  setLink(data.Description[0].children[1].url)
  } else { }
    

     





          setPost(data.Description)

      }
    
      useEffect(() => {
        fetchData();
      }, []);
 
      
      if (loading) {
        return <img src={load} alt={""} />
      }
      
      // const ele = [];
      
      // post.forEach(e => {
      //   if(e.type === 'upload') {
      //     ele.push(e);
      //   }
      // });
      const ele = post.filter(e => e.type === 'upload')

    
     
      return (
        <>
       <div className="head" style={{position:"fixed", float: "right"}}>
        <Link to="/portfolio"> <h2 className="back">&#8678; Back</h2></Link>
        <p style={{color: "#fff"}}>Client:</p>{ <h1>{text.title} </h1>}
        <p style={{color: "#fff"}}>Description:</p>{<p>{text.desc} </p>}
        <div className="link">
        {link && (
      <a href={link} target={"blank"} 
      rel="noopener noreferrer">View Page &#8680;</a>
    )}</div>

      
        </div>
       

        {ele.map((x,index) => (
       <div className="one" key={`post-${index}`} >
     <img className="mapImg" src={x.value.url} alt={""} />
      
     </div>) ) }
     </>
   )

  
    } 