import Intro from "../components/intro/Intro";
import GraphicDes from "../components/GraphicDes/GraphicDes";
import About from "../components/about/About";
import Filter from "../components/Filter";
import SeeMore from "../components/seeMore/SeeMore";
import Techno from "../components/techno/Techno"


export default function Home() {
  return (
    <>
      <Intro />
      <About />
      <Filter />
      <SeeMore />
      <GraphicDes />
      
      <Techno />
    
    </>
  );
}
