import { motion } from 'framer-motion';
import { useState } from 'react';


function Cantv() {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className='cantvWrap'>
      <div className='cantv' onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? 'is connecting...!' : `[ Connect Now ] -> click`}
      </div>
      <motion.img 
        src="https://cms-server.rene-huber.eu//media/dialUp-1.gif"
        onClick={() => setIsVisible(!isVisible)}
        alt="Dial"
        animate={isVisible ? { opacity: 1, y: -255, x: 420 } : { opacity: 0, x: 420 }}
        initial={{ opacity: 0, x: 100, y: -5 }}
        transition={{ duration: 0.5 }}
        style={{ width: "391px", height: "251px", cursor:"pointer" }}
      />
    </div>
  );
}
export default Cantv