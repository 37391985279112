import { useState, useEffect , useContext } from "react"
import { ThemeContext } from '../context'

const testimomino = {
  width: "100%",
  height: "auto",
};

const test={
  width: "50vw",
  height: "fit-content",
  margin: "0 auto",
  background: "#FFF",
  
}

function Testimonials() {
  const { loading } = useContext(ThemeContext);
  const [post, setPost] = useState([]);
  
  const fetchData = async () => {
    const response = await fetch(`https://cms-server.rene-huber.eu/api/tags?limit=0`);
    const data = await response.json();
    setPost(data.docs);
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  
  if (loading) {
    return <div style={{color: "#000", fontSize:"177px", fontFamily: 'Bebas Neue'}}>Loading...</div>;
  }

  return post.map((el, i) => (
    <div style={{background: "#FFF", borderRadius: "5px"}}>
    <div style={test}>
    <img key={el.i} style={testimomino} src={el.Image.url} alt={el.Title} />
    </div>
    </div>
  ));
}

export default Testimonials;
