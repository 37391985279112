import Slide2 from "../slider_2/Slide2";
import "./GraphicDes.css"
import LogoG from "../../img/graphicDesign.jpg"

export default function GraphicDes() {
  return (
    <div className="wrapGrapgicDesign">

<div className="LogoGraphic">
<img src={LogoG} alt="Graphic Design" />
<p>Graphic design is important in 2023 because it plays a crucial role in visual communication and marketing. It helps businesses to effectively convey their message and stand out in a crowded marketplace. In addition, with the increasing use of digital platforms and the internet, graphic design has become even more important for creating visually appealing websites, social media content, and digital advertising. Furthermore, good graphic design can also help to improve the user experience and increase engagement with a brand or product.</p>
</div>


     
        <Slide2 />
     


    </div>
  );
}
