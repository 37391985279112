import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeProvider } from "./context";
import { MouseProvider } from './MouseCTX';
import "./App_style.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  //   <React.StrictMode>
  // </React.StrictMode>
  <MouseProvider>
    <ThemeProvider>
    <BrowserRouter>
      <App />
      </BrowserRouter>
    </ThemeProvider>
    </MouseProvider>
    
 
);
