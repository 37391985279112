import { useContext } from "react";
import { useState } from "react"
import { ThemeContext } from '../../context'
import {categories} from "../../untils"
import "../../App_style.css"
function ButtonFilters() {
  const [tabActive, setTabActive] = useState("All")
  const { post , setFilterPost} = useContext(ThemeContext)
  
  const handleClickFilter = (value) =>{
    setTabActive(value);
    const filterPost = post.filter(post => post.Categories.includes(value))
   
  
    if(value === "All") {
      setFilterPost(post);
    } else {
      setFilterPost(filterPost);
    }
  
  }

  return (
    <div className="button-wrapper">
    <div className="latest"><h1 className="latest">Latest Web projects <span style={{color:'#40f4fd', fontSize:"43px",}}> &gt;</span></h1></div>
  <div className="buttonFilter"> 
    {categories.map((item) => (
        <button key={item.value}
        className={tabActive === item.value ? "active" : undefined}
        onClick={()=> handleClickFilter(item.value)}
        >
        {item.label}</button>
      ))}
      </div>
    </div>
  )
}

export default ButtonFilters