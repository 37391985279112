import { Navigate } from "react-router-dom";
import {useEffect, useState} from 'react';


function Enviado() {
  const [done, setDone] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDone(true)
      
      
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return <>{done &&  <Navigate to="/" />}<div style={{color: "#000", fontSize:"5rem", fontFamily: 'Bebas Neue', height:"100vh"}}>Message sent<br/>succesfully<br/>
  <span style={{fontSize:"51px", color: "#F00"}}>Redirect in 3..2..1..2..0</span>
  </div></>;
}

export default Enviado
