import { AnimatePresence } from "framer-motion";
import Posts from "../components/Posts/Posts"
import ButtonFilters from "../components/ButtonFilter/ButtonFilters"

function Filter() {
  return (
    <div className="gridbox">

    <ButtonFilters />
    <div className="image-container">
    <AnimatePresence>
          <Posts />
          </AnimatePresence>  
          </div>
    </div>
  )
}

export default Filter